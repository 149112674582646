import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { useState, type ChangeEvent } from "react";

import { Arrange, Skeleton, SkeletonList, TextField, Typography } from "@aviary";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";

import * as styles from "../ExperimentTester.styles";

import { Flipper } from "./Flipper";
import { useFlippersQuery } from "./useFlippers.query";

interface Props {
  onCloseDrawer: () => void;
}

const Flippers = ({ onCloseDrawer }: Props) => {
  const { tablet } = useBreakpoints();
  const { data, loading } = useFlippersQuery();
  const [searchString, setSearchString] = useState<string>("");

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setSearchString(value);
  };

  const handleTrailingIconClick = () => {
    setSearchString("");
  };

  const getFilteredList = () => {
    const rawList = [...data?.viewer?.flippers];
    if (searchString) {
      return rawList?.filter(flipper => flipper.name.includes(searchString.toLowerCase()));
    }

    return rawList;
  };

  const renderFlippers = () => {
    if (loading) {
      return (
        <>
          <Typography>Loading loading loading, keep the flippers loading, show/hide!</Typography>
          <SkeletonList number={3}>
            <Skeleton isFullWidth type="button" margin="topAndBottom" />
          </SkeletonList>
        </>
      );
    }

    return getFilteredList().map(({ name, result }) => (
      <Flipper key={name} name={name} active={result} onCloseDrawer={onCloseDrawer} />
    ));
  };

  return (
    <>
      <Arrange isFullWidth={tablet.lessThan} justify="center" data-testid="flippers-content">
        <TextField
          isFullWidth={tablet.lessThan}
          placeholder="Search for flipper(s)"
          value={searchString}
          onChange={handleSearchChange}
          css={[!tablet.lessThan && styles.searchBar]}
          inputIcon={faSearch}
          hasClearButton={!!searchString}
          onClearField={handleTrailingIconClick}
          autoFocus
        />
      </Arrange>
      <Arrange isFullWidth={tablet.lessThan} isVertical alignment="center" css={styles.content}>
        {renderFlippers()}
      </Arrange>
    </>
  );
};

export { Flippers };
