import type { ReactElement, ReactNode } from "react";
import { useEffect, Children, Fragment, isValidElement, cloneElement, useState } from "react";

import { Content } from "./Content";
import { Header } from "./Header";
import { Headers } from "./Headers";

import * as styles from "./Tabs.styles";

const TabHeadersType = (<Headers />).type;

interface Props {
  /**
   * Allows a tab to initially render as open, instead of all tabs being initially collapsed
   */
  initialTabId?: string;
  /**
   * Used in conjuction with `initialTabId`, always forces a tab to be open
   *
   * @default false
   */
  isUncloseable?: boolean;
  /**
   * Callback to update the current open tab
   */
  onChange?: (openTabId: string) => void;
  /**
   * Content for tabs to function, TabHeader, TabContent
   *
   * @default undefined
   */
  children?: ReactNode;
}

/**
 * Documentation:
 * https://aviary.docs.fullscript.cloud/components/navigation/Tabs
 */
const Tabs = ({
  initialTabId = null,
  isUncloseable = true,
  onChange = () => null,
  children,
  ...rest
}: Props) => {
  const [openTabId, setOpenTabId] = useState(initialTabId);

  useEffect(() => {
    setOpenTabId(initialTabId);
  }, [initialTabId]);

  const onTriggerClicked = targetTabId => {
    if (openTabId !== targetTabId) {
      setOpenTabId(targetTabId);
    } else if (!isUncloseable) {
      setOpenTabId(null);
    }
    onChange(targetTabId);
  };

  const cloneItem = (child: ReactElement) => {
    if (child.type === Fragment || child.type === "Symbol(react.fragment)") {
      return child.props.children.map(item => cloneItem(item));
    }
    // Only pass openTabId if child actually has a tabId or is of TabHeadersType
    if (child.props.tabId || child.type === TabHeadersType) {
      return cloneElement(child, {
        openTabId,
        onTriggerClicked,
        ...child.props,
      });
    }

    return child;
  };

  const renderChildren = () => {
    return Children.map(children, child => {
      if (isValidElement(child)) {
        return cloneItem(child);
      }
    });
  };

  return (
    <div css={styles.tabs} {...rest}>
      {renderChildren()}
    </div>
  );
};

Tabs.Content = Content;
Tabs.Header = Header;
Tabs.Headers = Headers;

export { Tabs, type Props as TabProps };
