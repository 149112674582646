import { useState } from "react";

import { Arrange, Toggle, Typography } from "@aviary";
import { useExperimentVar } from "@shared/context/experiment/experiment.store";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";
import { isStaging, isDevelopment } from "@shared/utils/fullscriptEnv/fullscriptEnv";
import type { ExperimentGroupParam } from "@shared/utils/persistedExperimentOverrides/persistedExperimentOverrides";
import {
  setShouldPersistOverrides,
  setPersistedExperimentOverrides,
  getShouldPersistOverrides,
  clearPersistedExperimentOverrides,
  addPersistedExperimentOverride,
} from "@shared/utils/persistedExperimentOverrides/persistedExperimentOverrides";

import { Experiment } from "./Experiment";

import * as styles from "./ABTests.styles";

const ABTests = () => {
  const { tablet } = useBreakpoints();
  const { currentExperiments, overrideValues } = useExperimentVar();
  const [persistGroups, setPersistGroups] = useState<boolean>(getShouldPersistOverrides());

  const handleOverrideSet = ({ experimentName, group }: ExperimentGroupParam) => {
    if ((!isDevelopment() && !isStaging()) || !persistGroups) return;
    addPersistedExperimentOverride({ experimentName, group });
  };

  const renderExperiment = (experimentName: string) => {
    return (
      <Experiment
        key={experimentName}
        experimentName={experimentName}
        onOverrideSet={handleOverrideSet}
      />
    );
  };

  const renderExperiments = () => {
    const top = "Which Pat is best?";
    const list = Object.keys(currentExperiments);

    const index = list.indexOf(top);
    if (index >= 0) {
      list.splice(list.indexOf(top), 1);

      return [top, ...list].map(renderExperiment);
    }

    return list.map(renderExperiment);
  };

  const handlePersistChangesToggle = () => {
    setPersistGroups(currentState => {
      if (currentState) {
        clearPersistedExperimentOverrides();
      } else {
        setPersistedExperimentOverrides(overrideValues);
      }

      setShouldPersistOverrides(!currentState);

      return !currentState;
    });
  };

  const renderPersistGroupsToggle = () => {
    if (!isDevelopment() && !isStaging()) return;

    return (
      <Arrange css={styles.persistContainer} alignment="center" justify="center">
        <Typography type="h2">Persist groups</Typography>
        <Toggle isOn={persistGroups} onClick={() => handlePersistChangesToggle()} />
      </Arrange>
    );
  };

  return (
    <>
      {renderPersistGroupsToggle()}
      <Arrange isVertical alignment="center" css={styles.content} isFullWidth={tablet.lessThan}>
        {renderExperiments()}
      </Arrange>
    </>
  );
};

export { ABTests };
