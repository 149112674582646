import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";

import { Footer } from "./Footer/Footer";

import * as styles from "./ExperimentTester.styles";

const Scrollable = ({ children }) => {
  const { tablet } = useBreakpoints();

  const height = tablet.lessThan ? styles.accountFooter : styles.noFooter;
  const scrollable = [styles.scrollable, height];

  const renderFooter = () => {
    if (!tablet.lessThan) return null;

    return <Footer />;
  };

  return (
    <>
      <div css={scrollable}>{children}</div>
      {renderFooter()}
    </>
  );
};

export { Scrollable };
