import { css, type Theme } from "@emotion/react";

import { Direction } from "@aviary/types";
import { hexToRgba } from "@styles/emotion-styles/helpers";
import { dimensions, layers } from "@styles/index";

export const maxIndexDrawer = css`
  z-index: ${layers.indexDrawer};
`;

export const container = (theme: Theme) => css`
  background-color: ${theme.surface.level0};
  overflow: hidden;
`;

export const fullHeightContainer = css`
  height: 100dvh;
  overflow: auto;
  width: inherit;
  max-width: inherit;
`;

export const flexColumn = css`
  display: flex;
  flex-direction: column;
`;

export const drawer = (theme: Theme) => css`
  && {
    background: none;
  }
  & .MuiBackdrop-root {
    background: ${hexToRgba(theme.surface.overlayBackdrop, theme.opacity.overlayBackdrop)};
  }
`;

const drawerWidths = {
  xsmall: css`
    @media (min-width: ${dimensions.tablet}) {
      width: 50vw;
    }

    @media (min-width: ${dimensions.widescreenMax}) {
      width: 40vw;
    }
  `,
  small: css`
    @media (min-width: ${dimensions.tablet}) {
      width: 60vw;
    }

    @media (min-width: ${dimensions.desktop}) {
      width: 50vw;
    }
  `,
  medium: css`
    @media (min-width: ${dimensions.tablet}) {
      width: 70vw;
    }

    @media (min-width: ${dimensions.widescreenMax}) {
      width: 60vw;
    }
  `,
  large: css`
    @media (min-width: ${dimensions.tablet}) {
      width: 80vw;
      max-width: ${dimensions.maxContainer};
    }
  `,
};

export const opensFrom = {
  [Direction.LEFT]: drawerWidths,
  [Direction.RIGHT]: drawerWidths,
  [Direction.BOTTOM]: css`
    @media (min-width: ${dimensions.tablet}) {
      height: 60dvh;
    }
  `,
  [Direction.TOP]: css`
    @media (min-width: ${dimensions.tablet}) {
      height: 60dvh;
    }
  `,
};

export const isDeepLinking = css`
  @media (min-width: ${dimensions.tablet}) {
    height: fit-content;
  }
`;
