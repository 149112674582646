import { clearOverrides } from "@shared/context/experiment/experiment.store";
import type { OverrideValues } from "@shared/context/experiment/experiment.store";

import { PERSISTED_GROUPS_KEY, SHOULD_PERSIST_GROUPS_KEY } from "./constants";

type ExperimentGroupParam = {
  experimentName: string;
  group: string;
};

/**
 * Adds the specified experimentName and group to the list of persisted group overrides
 */
const addPersistedExperimentOverride = ({ experimentName, group }: ExperimentGroupParam) => {
  const serializedPersistedGroups = localStorage.getItem(PERSISTED_GROUPS_KEY);

  let persistedGroups = {};

  if (serializedPersistedGroups) {
    persistedGroups = JSON.parse(serializedPersistedGroups);
  }

  localStorage.setItem(
    PERSISTED_GROUPS_KEY,
    JSON.stringify({ ...persistedGroups, [experimentName]: group })
  );
};

/**
 * Persists a group of experiment overrides.
 * if you want to just add one experiemnt override, @see addPersistedExperimentOverride
 */
const setPersistedExperimentOverrides = (overrideValues: OverrideValues) => {
  localStorage.setItem(PERSISTED_GROUPS_KEY, JSON.stringify(overrideValues));
};

/**
 * Clear the list of persisted experiment group overrides
 */
const clearPersistedExperimentOverrides = () => {
  localStorage.removeItem(PERSISTED_GROUPS_KEY);
  clearOverrides();
};

/**
 * Sets whether or not overrides should be persisted
 */
const setShouldPersistOverrides = (value: boolean) => {
  localStorage.setItem(SHOULD_PERSIST_GROUPS_KEY, value.toString());
};

/**
 * Get whether or not overrides should be persisted
 */
const getShouldPersistOverrides = (): boolean => {
  try {
    const shouldPersistOverrides = localStorage.getItem(SHOULD_PERSIST_GROUPS_KEY) === "true";

    return shouldPersistOverrides;
  } catch {
    // If localStorage isn't available, default to persistence should be false
    return false;
  }
};

export {
  addPersistedExperimentOverride,
  clearPersistedExperimentOverrides,
  setPersistedExperimentOverrides,
  setShouldPersistOverrides,
  getShouldPersistOverrides,
  type ExperimentGroupParam,
};
